import Link from "next/link";
import { CreditCard, Keyboard, LogOut, Settings } from "lucide-react";
import { useTranslations } from "next-intl";

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@acme/ui";

import { UserAccess } from "~/app/_actions/user";
import { env } from "~/env.mjs";

export function DropdownAvatar({
  userName,
  avatarUrl,
  access,
}: {
  userName: string;
  avatarUrl?: string;
  access: UserAccess | null;
}) {
  const t = useTranslations("auth.avatar-dropdown");

  const navigation = [
    {
      label: t("billing"),
      href: "/billing",
      obj: CreditCard,
      disabled: true,
    },
  ];

  const menuItems = navigation.filter((item) => !item.disabled);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Avatar
          className="relative ml-3 cursor-pointer"
          data-testid="user-avatar"
        >
          <AvatarImage
            src={avatarUrl || "/static/default-avatar.png"}
            alt={userName}
          />
          <AvatarFallback>
            {userName ? userName.slice(0, 2) : "US"}
          </AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>
          {t("myAccount")}{" "}
          <p className="text-xs leading-none text-muted-foreground">
            {userName}
            {(env.ENABLE_PARTNER_LINK && "sssss") || "eeee"}
          </p>
          {env.ENABLE_PARTNER_LINK && access && (
            <>
              <DropdownMenuSeparator />

              <div>Current Access</div>
              <p className="text-xs leading-none text-muted-foreground">
                {mkAccessLabel(access)}
              </p>
              {/* <Link href={item.href}>
              <item.obj className="mr-2 h-4 w-4" />
              <span>{item.label}</span>
              </Link> */}
              {/* ] */}
            </>
          )}
        </DropdownMenuLabel>

        <DropdownMenuSeparator />

        <DropdownMenuGroup>
          {menuItems.map((item) => (
            <DropdownMenuItem key={item.label} asChild>
              <Link href={item.href}>
                <item.obj className="mr-2 h-4 w-4" />
                <span>{item.label}</span>
              </Link>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
        {menuItems.length > 0 && <DropdownMenuSeparator />}
        {/* <DropdownMenuGroup>
          <DropdownMenuItem>
            <Users className="mr-2 h-4 w-4" />
            <span>Team</span>
          </DropdownMenuItem>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              <UserPlus className="mr-2 h-4 w-4" />
              <span>Invite users</span>
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                <DropdownMenuItem>
                  <Mail className="mr-2 h-4 w-4" />
                  <span>Email</span>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <MessageSquare className="mr-2 h-4 w-4" />
                  <span>Message</span>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem>
                  <PlusCircle className="mr-2 h-4 w-4" />
                  <span>More...</span>
                </DropdownMenuItem>
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
          <DropdownMenuItem>
            <Plus className="mr-2 h-4 w-4" />
            <span>New Team</span>
            <DropdownMenuShortcut>⌘+T</DropdownMenuShortcut>
          </DropdownMenuItem>
        </DropdownMenuGroup> */}
        {/* <DropdownMenuSeparator /> */}
        {/* <DropdownMenuItem>
          <Github className="mr-2 h-4 w-4" />
          <span>GitHub</span>
        </DropdownMenuItem>
        <DropdownMenuItem>
          <LifeBuoy className="mr-2 h-4 w-4" />
          <span>Support</span>
        </DropdownMenuItem>
        <DropdownMenuItem disabled>
          <Cloud className="mr-2 h-4 w-4" />
          <span>API</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator /> */}
        <DropdownMenuItem asChild>
          <Link href="/logout">
            <LogOut className="mr-2 h-4 w-4" />
            <span>{t("logoutButton")}</span>
          </Link>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function mkAccessLabel(access?: UserAccess) {
  if (!access) return "";
  return (
    (access.org.name || "My Org") +
    (access.client ? " ▶ " + access.client.name : "")
  );
}
